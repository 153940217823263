import React from "react";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Tooltip from "@material-ui/core/Tooltip";
import PropTypes from "prop-types";

function UnixTimestampPrecision(props) {
    return (
        <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">Precision</FormLabel>
            <RadioGroup row aria-label="precision" name="precision" value={props.value} onChange={props.onChange}>
                <Grid item container direction="row" xs={12} md={props.fullWidth ? 6 : 12} xl={6}>
                    <Grid item xs>
                        <FormControlLabel value="SEC" control={<Radio />} label="Seconds" disabled={props.disabledOptions.SEC} />
                    </Grid>
                    <Grid item xs>
                        <FormControlLabel value="MILLI" control={<Radio />} label="Milliseconds" disabled={props.disabledOptions.MILLI} />
                    </Grid>
                </Grid>
                <Grid item container direction="row" xs={12} md={props.fullWidth ? 6 : 12} xl={6}>
                    <Grid item xs>
                        {props.microPrecision ?
                            <FormControlLabel value="MICRO" control={<Radio />} label="Microseconds" disabled={props.disabledOptions.MICRO} />
                            :
                            <Tooltip title="Micro precision is not precise in this case, so microseconds will be completed with 000">
                                <FormControlLabel value="MICRO" control={<Radio />} label="Microseconds *" disabled={props.disabledOptions.MICRO} />
                            </Tooltip>
                        }
                    </Grid>
                    <Grid item xs>
                        {props.nanoPrecision ?
                            <FormControlLabel value="NANO" control={<Radio />} label="Nanoseconds" disabled={props.disabledOptions.NANO} />
                            :
                            <Tooltip title="Nano precision is not precise in this case, so nanoseconds will be completed with 000">
                                <FormControlLabel value="NANO" control={<Radio/>} label="Nanoseconds *" disabled={props.disabledOptions.NANO}/>
                            </Tooltip>
                        }
                    </Grid>
                </Grid>
            </RadioGroup>
        </FormControl>
    );
}

UnixTimestampPrecision.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    disabledOptions: PropTypes.object,
    fullWidth: PropTypes.bool,
    microPrecision: PropTypes.bool,
    nanoPrecision: PropTypes.bool,
};

UnixTimestampPrecision.defaultProps = {
    disabledOptions: {},
    fullWidth: false,
    microPrecision: false,
    nanoPrecision: false,
};

export default UnixTimestampPrecision;