import React, {useState} from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {api} from "../../services/api";
import PropTypes from 'prop-types';

export const defaultTimeZone = { label: 'UTC', utcOffset: '+00:00' };

function TimeZoneField(props) {

    const [timeZoneTerm, setTimeZoneTerm] = useState('');

    function handleChangeTimeZoneTerm(event, value) {
        setTimeZoneTerm(value)
        if(value && value.length > 2) {
            api.get('/timeZones/findByTerm?term=' + value)
            .then((response) => {
                props.onTimeZonesFound(response.data);
            });
        }
    }

    const autoCompleteProps = Object.fromEntries(Object.entries({...props}).filter(([key]) => !['onTimeZonesFound'].includes(key)));

    return (
        <Autocomplete
            selectOnFocus
            autoSelect
            openOnFocus
            handleHomeEndKeys
            {...autoCompleteProps}
            inputValue={timeZoneTerm}
            onInputChange={handleChangeTimeZoneTerm}
            options={props.options}
            groupBy={(option) => {
                switch(option.type) {
                    case 'CITY' : return 'City';
                    case 'TIME_ZONE_ID' : return 'TimeZone ID';
                    case 'UTC_OFFSET' : return 'UTC Offset';
                    default: return option.type;
                }
            }}
            getOptionLabel={(option) => option.label}
            getOptionSelected={() => true}
            style={{ width: 8000 }}
            renderInput={(params) => <TextField {...params} variant="outlined" label="TimeZone" fullWidth />}
        />
    );
}

TimeZoneField.propTypes = {
    value: PropTypes.object.isRequired,
    options: PropTypes.array.isRequired,
    onTimeZonesFound: PropTypes.func.isRequired
};

export default TimeZoneField;