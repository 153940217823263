import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Card from "@material-ui/core/Card";
import {CardContent} from "@material-ui/core";
import CardHeader from "@material-ui/core/CardHeader";

function Home() {
    const useStyles = makeStyles(theme => ({
        card: {
            // flexGrow: 1,
            background: "#eee",
            height: "calc(100vh - 64px - 40px)" // (full-height) - (header) - (container-padding)
        }
    }));
    const classes = useStyles();

    return (
        <Card className={classes.card}>
            <CardHeader title="Home" />
            <CardContent>
            </CardContent>
        </Card>
    );
}

export default Home;