import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import {Grid} from "@material-ui/core";
import PasswordGenerator from "./PasswordGenerator";

function PasswordGeneratorPage() {
    const useStyles = makeStyles(theme => ({
        toolBackground: {
            flexGrow: 1,
            padding: 20,
            background: '#eee'
        }
    }));
    const classes = useStyles();

    return (
        <Paper className={classes.toolBackground}>
            <Grid container direction="row" spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h3" component="h3">
                        Password Generator
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <PasswordGenerator/>
                </Grid>
            </Grid>
        </Paper>
    );
}

export default PasswordGeneratorPage;