import React, {useState} from "react";
import {Location, navigate} from "@reach/router";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {api} from "../../services/api";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    header: {
        // backgroundColor: "rgb(81,21,123)",
        backgroundImage: "linear-gradient(to right, rgb(81,21,123), rgb(51,21,123))",
        opacity: 0.9
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    }
}));

function Header() {
    const classes = useStyles();

    const [logoutDisabled, setLogoutDisabled] = useState(false);

    // const location = useLocation();

    function logout() {
        setLogoutDisabled(true);
        api.post('/logout').then(r => {
            navigate('/login');
        }).finally(() => {
            setLogoutDisabled(false)
        });
    }

    return (
        <div className={classes.root}>
            <AppBar position="static" className={classes.header}>
                <Toolbar>
                    {/*<IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">*/}
                    {/*    <MenuIcon />*/}
                    {/*</IconButton>*/}
                    <Typography variant="h6" className={classes.title}>
                        Trampo Tools
                    </Typography>
                    {/*<Button color="inherit" onClick={toHome}>*/}
                    {/*    Home*/}
                    {/*</Button>*/}
                    {/*<Button color="inherit" onClick={showMoreTools}>*/}
                    {/*    More tools*/}
                    {/*</Button>*/}
                    <Location>
                        {({ location }) => (
                            <React.Fragment>
                                {location.pathname !== '/login' && false &&
                                    <Button color="inherit" disabled={logoutDisabled} onClick={logout}>Logout</Button>
                                }
                            </React.Fragment>
                        )}
                    </Location>
                </Toolbar>
            </AppBar>
        </div>
    );
}

export default Header;