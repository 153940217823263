import React from "react";
import NumberFormat from 'react-number-format';
import TextField from "@material-ui/core/TextField";

function NumericField (props) {
    const {inputRef, onChange, ...other} = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            allowNegative={false}
            isNumericString
        />
    );
}

function UnixTimestampField(props) {

    return (
        <TextField fullWidth variant="outlined" label="Unix timestamp"
                   {...props}
                   InputProps={{
                       inputComponent: NumericField
                   }}
        />
    );
}

export default UnixTimestampField;