import React, {useEffect, useState} from "react";
import Card from "@material-ui/core/Card";
import {CardContent} from "@material-ui/core";
import PausePlay from "../utils/PausePlay";
import CopyToClipboardButton from "../utils/CopyToClipboardButton";
import CardHeader from "@material-ui/core/CardHeader";
import Typography from "@material-ui/core/Typography";
import UnixTimestampPrecision from "./UnixTimestampPrecision";
import Grid from "@material-ui/core/Grid";

function CurrentTimestamp() {
    const lastUsedPrecisionKey = 'currentUnixTimestamp.lastUsedPrecision';

    const initializePrecisionState = () => {
        return localStorage.getItem(lastUsedPrecisionKey) || 'MILLI';
    };

    const [currentTimestamp, setCurrentTimestamp] = useState(new Date().getTime());
    const [paused, setPaused] = useState(false);
    const [precision, setPrecision] = useState(initializePrecisionState);

    useEffect(() => {
        const timerId = setInterval(() => {
            if(!paused) {
                setCurrentTimestamp(getTimestamp(precision));
            }
        }, 1000);
        return () => {
            clearInterval(timerId);
        }
    });

    const getTimestamp = function(precision) {
        switch (precision) {
            case 'SEC': return Math.floor(new Date().getTime() / 1000);
            case 'MILLI': return new Date().getTime();
            case 'MICRO': return Math.floor((performance.now() + performance.timeOrigin) * 1000);
            case 'NANO': return Math.floor((performance.now() + performance.timeOrigin) * 1000000);
            default: return new Date().getTime();
        }
    };

    const onPauseToggle = function(paused) {
        setPaused(paused);
    };

    function handleChangePrecision(event, value) {
        setPrecision(value);
        localStorage.setItem(lastUsedPrecisionKey, value);
    }

    return (
        <Card>
            <CardHeader title="Current unix timestamp since epoch" />
            <CardContent>
                <Grid container direction="row" justify="center" spacing={2}>
                    <Grid item container justify="center" xs={12}>
                        <div style={{width: '36px'}} />
                        <CopyToClipboardButton aria-label="copy current timestamp">
                            <Typography variant="h4" color="secondary">
                                {currentTimestamp}
                            </Typography>
                        </CopyToClipboardButton>
                        <PausePlay paused={paused} onPauseToggle={onPauseToggle}/>
                    </Grid>
                    <Grid item xs={12}>
                        <UnixTimestampPrecision
                            fullWidth
                            microPrecision={true}
                            value={precision}
                            onChange={handleChangePrecision}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

export default CurrentTimestamp;