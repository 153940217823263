export default class OptionHistoryService {

    constructor(key, defaultOptionList) {
        this.key = key;
        this.defaultOptionList = (defaultOptionList || []);

        const item = localStorage.getItem(this.key);

        this.state = {
            recentlyUsedOptions: item ? JSON.parse(item) : []
        };
    }

    sortOptions = (options) => {
        return options.sort((a, b) => {
            if(a.type === b.type) {
                if(a.type === 'Recently used') {
                    if(a.order === b.order) {
                        return 0
                    } else {
                        return a.order > b.order ? -1 : 1
                    }
                }
                return a.label.localeCompare(b.label);
            } else if(a.type === 'Recently used') {
                return -1;
            }
            return 1;
        })
    };

    initializedOptionList = () => {
        return this.defaultOptionList.length ? this.state.recentlyUsedOptions.concat(this.defaultOptionList) : this.state.recentlyUsedOptions;
    };

    initializedOption = (defaultInitialOption) => {
        defaultInitialOption.type = 'Recently used';
        return this.state.recentlyUsedOptions.length ? this.state.recentlyUsedOptions[0] : defaultInitialOption;
    };

    addToRecentlyUsedOptions = (option) => {
        if(!option) {
            return this.state.recentlyUsedOptions;
        }
        let options = this.state.recentlyUsedOptions.filter((value) => value.label !== option.label);
        options = this.sortOptions(options);
        if(options.length >= 5) {
            options.pop();
        }
        option.type = 'Recently used'
        options.unshift(option);
        localStorage.setItem(this.key, JSON.stringify(options));
        this.state.recentlyUsedOptions = options;
        return options
    };
    
    setAvailableOptions = (options) => {
        this.state.optionsAvailable = options;  
    };

    getOptionList = (options) => {
        let optionList;
        if(options) {
            options = options.filter(option => option.type !== 'Recently used');
            optionList = this.state.recentlyUsedOptions.concat(options)
        } else {
            optionList = this.defaultOptionList.length
                ? this.state.recentlyUsedOptions.concat(this.defaultOptionList)
                : this.state.recentlyUsedOptions;
        }
        return this.sortOptions(optionList);
    };
}

