import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import CurrentTimestamp from "./CurrentUnixTimestamp";
import UnixTimestampToDateTime from "./UnixTimestampToDateTime";
import {Grid} from "@material-ui/core";
import DateTimeToUnixTimestamp from "./DateTimeToUnixTimestamp";

function UnixTimestampToolsPage() {
    const useStyles = makeStyles(theme => ({
        toolBackground: {
            flexGrow: 1,
            padding: 20,
            background: '#eee',
            // height: 'calc(100vh - 64px - 40px)' // (full-height) - (header) - (container-padding)
        }
    }));
    const classes = useStyles();

    return (
        <Paper className={classes.toolBackground}>
            <Grid container direction="row" spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h3" component="h3">
                        Unix Timestamp Tools
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <CurrentTimestamp/>
                </Grid>
                <Grid item xs={12}>
                    <UnixTimestampToDateTime/>
                </Grid>
                <Grid item xs={12}>
                    <DateTimeToUnixTimestamp/>
                </Grid>
            </Grid>
        </Paper>
    );
}

export default UnixTimestampToolsPage;