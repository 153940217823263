import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import CssBaseline from "@material-ui/core/CssBaseline";
import responsiveFontSizes from "@material-ui/core/styles/responsiveFontSizes";
import {createMuiTheme, ThemeProvider} from "@material-ui/core/styles";

function getTheme() {
    let theme = createMuiTheme({
        "palette": {
            "common": {
                "black": "#000",
                "white": "#fff"
            },
            "background": {
                // "paper": "rgba(169, 169, 169, 0.17)",
                "paper": "rgb(238,238,238)",
                "default": "#fafafa"
            },
            "primary": {
                "light": "rgba(31, 168, 225, 0.54)",
                "main": "rgb(64,23,114)",
                "dark": "rgb(49,23,114)",
                "contrastText": "#fff"
            },
            "secondary": {
                "light": "rgba(14, 14, 14, 0.55)",
                "main": "rgba(14, 14, 14, 1)",
                "dark": "rgba(14, 14, 14, 1)",
                "contrastText": "#fff"
            },
            "error": {
                "light": "rgba(252, 114, 29, 0.66)",
                "main": "rgba(252, 114, 29, 1)",
                "dark": "rgba(252, 60, 29, 1)",
                "contrastText": "#fff"
            },
            "text": {
                "primary": "rgba(14, 14, 14, 1)",
                "secondary": "rgb(37,18,111)",
                "disabled": "rgba(0, 0, 0, 0.38)",
                "hint": "rgba(0, 0, 0, 0.38)"
            }
        },
        overrides: {
            MuiTooltip: {
                arrow: {
                    color: "#E9E9EA",
                },
                tooltip: {
                    backgroundColor: "rgb(51,21,123)",
                }
            },
            MuiAutocomplete: {
                listbox: {
                    backgroundColor: "#E3E3E3"
                },
                noOptions: {
                    backgroundColor: "#E3E3E3",
                },
                option: {
                    '&:hover,&:active': {
                        backgroundColor: "#E3E3E3",
                        backgroundImage: "linear-gradient(to right, transparent, rgb(51,21,123,.6))",
                    },
                },
                groupLabel: {
                    backgroundImage: "linear-gradient(to right, rgb(81,21,123), rgb(51,21,123))",
                    color: "#E9E9EA",
                    '&:hover,&:active': {},
                }
            }
        },
    });
    theme = responsiveFontSizes(theme);

    return theme;
}

const theme = getTheme();

ReactDOM.render(
    // <React.StrictMode>
    <ThemeProvider theme={theme}>
        <CssBaseline/>
        <App />
    </ThemeProvider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
