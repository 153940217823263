import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import Slide from "@material-ui/core/Slide";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import {Typography} from "@material-ui/core";

function CopyToClipboardButton(props) {
    const [snackOpen, setSnackOpen] = React.useState(false);
    const [transition, setTransition] = React.useState(undefined);

    function copyToClipboard(event) {
        const text = event.currentTarget.querySelector('.contentToCopy').innerText;
        navigator.clipboard.writeText(text).then(() => {
            setTransition(() => TransitionUp);
            setSnackOpen(true);
        });
    }

    const handleClose = () => {
        setSnackOpen(false);
    };

    function TransitionUp(props) {
        return <Slide {...props} direction="up" />;
    }

    return (
        <React.Fragment>
            <Tooltip arrow title="Click to copy" aria-label={props.ariaLabel}>
                <Button onClick={copyToClipboard} variant="outlined" color="secondary">
                    <Grid container justify="center">
                        <Grid item xs={12} className="contentToCopy">
                            {props.children}
                        </Grid>
                        <Grid item>
                            <Typography variant="caption" color="textSecondary">Click to copy</Typography>
                        </Grid>
                    </Grid>
                </Button>
            </Tooltip>
            <Snackbar
                open={snackOpen}
                onClose={handleClose}
                TransitionComponent={transition}
                key="text-copied"
            >
                <Alert elevation={6} variant="filled" onClose={handleClose} severity="success">
                    Current timestamp copied to the clipboard!
                </Alert>
            </Snackbar>
        </React.Fragment>
    );
}

export default CopyToClipboardButton;