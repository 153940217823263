import React from 'react';
import './App.css';
import Container from "@material-ui/core/Container";
import {Router} from "@reach/router";
import Header from "./components/header/Header";
import Home from "./components/home/Home";
import ToolList from "./components/toolList/ToolList";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Hidden} from "@material-ui/core";
import UnixTimestampToolsPage from "./components/unixTimestampTools/UnixTimestampToolsPage";
import PasswordGeneratorPage from "./components/passwordGenerator/PasswordGeneratorPage";

function App() {
    const useStyles = makeStyles(theme => ({
        root: {
            flexGrow: 1,
            padding: 20
        }
    }));
    const classes = useStyles();
    return (
        <Container maxWidth={false} disableGutters={true} className="App-background">
            <Header/>
            <div className={classes.root}>
                <Grid container spacing={1} justify="space-between" direction="row" alignItems="flex-start">
                    <Hidden lgDown>
                        <Grid item xs />
                    </Hidden>
                    <Grid item xs={12} sm={9} md={9} xl={8}>
                        <Router>
                            <Home path="/"/>
                            <UnixTimestampToolsPage path="unixTimestampTools"/>
                            <PasswordGeneratorPage path="passwordGenerator"/>
                        </Router>
                    </Grid>
                    <Grid item xs={false} sm={3} md={3} xl={2}>
                        <ToolList />
                    </Grid>
                    <Hidden lgDown>
                        <Grid item xs />
                    </Hidden>
                </Grid>
            </div>
        </Container>
    );
}

export default App;
