import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PropTypes from "prop-types";
import {createFilterOptions} from "@material-ui/lab";

export const defaultFormatOptions = function() {
    return [
        { label: 'yyyy-MM-dd', type: 'Most common' },
        { label: 'dd/MM/yyyy', type: 'Most common' },
        { label: 'dd.MM.yyyy', type: 'Most common' },
        { label: 'dd-MM-yyyy', type: 'Most common' },
        { label: 'MM/dd/yyyy', type: 'Most common' },
        { label: 'HH:mm:ss', type: 'Most common' },
        { label: 'HH:mm:ss.SSS', type: 'Most common' },
        { label: 'HH:mm:ss.SSSSSS', type: 'Most common' },
        { label: 'HH:mm:ss.SSSSSSSSS', type: 'Most common' },
        { label: 'hh:mm:ss a', type: 'Most common' },
        { label: 'hh:mm:ss.SSS a', type: 'Most common' },
        { label: 'hh:mm:ss.SSSSSS a', type: 'Most common' },
        { label: 'hh:mm:ss.SSSSSSSSS a', type: 'Most common' },
        { label: 'yyyy-MM-dd HH:mm:ss', type: 'Most common' },
        { label: 'yyyy-MM-dd HH:mm:ss.SSS', type: 'Most common' },
        { label: 'dd/MM/yyyy HH:mm:ss', type: 'Most common' },
        { label: 'dd/MM/yyyy HH:mm:ss.SSS', type: 'Most common' },
        { label: 'dd.MM.yyyy HH:mm:ss', type: 'Most common' },
        { label: 'dd.MM.yyyy HH:mm:ss.SSS', type: 'Most common' },
        { label: 'dd-MM-yyyy HH:mm:ss', type: 'Most common' },
        { label: 'dd-MM-yyyy HH:mm:ss.SSS', type: 'Most common' },
        { label: 'MM/dd/yyyy hh:mm:ss a', type: 'Most common' },
        { label: 'MM/dd/yyyy hh:mm:ss.SSS a', type: 'Most common' },
        { label: 'yyyy-MM-dd HH:mm:ss Z', type: 'Most common' },
        { label: 'yyyy-MM-dd HH:mm:ss.SSS Z', type: 'Most common' },
    ];
};

function DateTimeFormatField(props) {

    const filterOptions = createFilterOptions({
        ignoreCase: false,
        limit: 20,
    });

    return (
        <Autocomplete
            freeSolo
            selectOnFocus
            autoSelect
            openOnFocus
            {...props}
            filterOptions={filterOptions}
            options={props.options}
            groupBy={(option) => option.type}
            getOptionLabel={(option) => typeof option === 'string' ? option : option.label}
            style={{ width: 8000 }}
            renderInput={(params) => <TextField {...params} variant="outlined" label="Date/Time format" fullWidth />}
        />
    );
}

DateTimeFormatField.propTypes = {
    value: PropTypes.object.isRequired,
    options: PropTypes.array.isRequired,
};

export default DateTimeFormatField;