import axios from "axios";

const devUrl = `${window.location.protocol}//${window.location.hostname}:8080/api`
const prodUrl = `api.${window.location.origin}`
const apiBaseUrl = process.env.NODE_ENV === 'production' ? prodUrl : devUrl;

export const api = axios.create({
    baseURL: apiBaseUrl,
    withCredentials: true,
    timeout: 10000
});
