import React, {useState} from "react";
import Card from "@material-ui/core/Card";
import {CardContent} from "@material-ui/core";
import CopyToClipboardButton from "../utils/CopyToClipboardButton";
import CardHeader from "@material-ui/core/CardHeader";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import UnixTimestampPrecision from "./UnixTimestampPrecision";
import OptionHistoryService from "../../services/optionHistoryService";
import {useMountEffect} from "../../services/hooks";
import TimeZoneField, {defaultTimeZone} from "../utils/TimeZoneField";
import DateTimeFormatField, {defaultFormatOptions} from "../utils/DateTimeFormatField";
import DateTimeField from "../utils/DateTimeField";
import {zonedTimeToUtc} from 'date-fns-tz'

function DateTimeToUnixTimestamp() {
    const lastUsedPrecisionKey = 'dateTimeToUnixTimestamp.lastUsedPrecision';
    const formatHistoryService = new OptionHistoryService('dateTimeToUnixTimestamp.recentlyUsedFormats', defaultFormatOptions());
    const timeZoneHistoryService = new OptionHistoryService('dateTimeToUnixTimestamp.recentlyUsedTimesZone');

    const initializePrecisionState = () => {
        return localStorage.getItem(lastUsedPrecisionKey) || 'MILLI';
    };

    const [dateTime, setDateTime] = useState(new Date());
    const [precision, setPrecision] = useState(initializePrecisionState);
    const [formatDisabled, setFormatDisabled] = useState(false);
    const [formatOptions, setFormatOptions] = useState(formatHistoryService.initializedOptionList);
    const [format, setFormat] = useState(formatHistoryService.initializedOption({ label: 'yyyy-MM-dd HH:mm:ss.SSS Z' }));
    const [timeZone, setTimeZone] = useState(timeZoneHistoryService.initializedOption(defaultTimeZone));
    const [timeZoneOptions, setTimeZoneOptions] = useState([]);
    const [unixTimestamp, setUnixTimestamp] = useState(new Date().getTime());

    const toUnixTimestamp = (dateTime, precision) => {
        const ts = dateTime.getTime();
        switch (precision) {
            case 'SEC': return ts.toString().substring(0, 10);
            case 'MICRO': return ts + '000';
            case 'NANO': return ts + '000000';
            default: return ts;
        }
    };

    const convertToUnixTimestamp = function(unixTimestamp, format, timeZone, precision) {
        if(!timeZone) {
            setTimeZone(defaultTimeZone);
            timeZone = defaultTimeZone;
        }
        const utcTime = zonedTimeToUtc(dateTime, timeZone.utcOffset);
        setUnixTimestamp(toUnixTimestamp(utcTime, precision));
        formatHistoryService.addToRecentlyUsedOptions(format);
        const formats = formatHistoryService.getOptionList();
        setFormatOptions(formats);
        timeZoneHistoryService.addToRecentlyUsedOptions(timeZone);
        const timeZones = timeZoneHistoryService.getOptionList();
        setTimeZoneOptions(timeZones);
    };

    useMountEffect(() => {
        convertToUnixTimestamp(unixTimestamp, format, timeZone, precision);
    }, [convertToUnixTimestamp, unixTimestamp, format, timeZone, precision]);

    function handleChangePrecision(event, value) {
        setPrecision(value);
        localStorage.setItem(lastUsedPrecisionKey, value);
    }

    function handleChangeFormat(event, value) {
        setFormatDisabled(!value);
        if(!value) return;
        if(typeof value === 'string') {
            setFormat({ label: value, type: 'Recently used' });
        } else {
            setFormat(value);
        }
    }

    function handleChangeTimeZone(event, value) {
        setTimeZone(value);
    }

    function handleClickFormatButton() {
        convertToUnixTimestamp(unixTimestamp, format, timeZone, precision);
    }

    function handleSubmit(event) {
        if(event.key === 'Enter') {
            convertToUnixTimestamp(unixTimestamp, format, timeZone, precision);
        }
    }

    function handleTimeZonesFound(timeZones) {
        timeZones = timeZoneHistoryService.getOptionList(timeZones);
        setTimeZoneOptions(timeZones);
    }

    return (
        <Card>
            <CardHeader title="Unix timestamp to date/time" />
            <CardContent>
                <form noValidate autoComplete="off">
                    <Grid container direction="row" spacing={2} alignItems="stretch" justify="center">
                        <Grid item container xs={12} sm={6} lg={6}>
                            <DateTimeFormatField
                                value={format}
                                options={formatOptions}
                                onChange={handleChangeFormat}
                                onKeyDown={handleSubmit}
                            />
                        </Grid>
                        <Grid item container xs={12} sm={6} lg={6}>
                            <TimeZoneField
                                value={timeZone}
                                options={timeZoneOptions}
                                onTimeZonesFound={handleTimeZonesFound}
                                onChange={handleChangeTimeZone}
                                onKeyDown={handleSubmit}
                            />
                        </Grid>
                        <Grid item container xs={12} sm={6} lg={6}>
                            <DateTimeField
                                value={dateTime}
                                onChange={setDateTime}
                                format={format.label}
                                timeZone={timeZone.utcOffset}
                            />
                        </Grid>
                        <Grid item container xs={12}>
                            <UnixTimestampPrecision
                                fullWidth
                                value={precision}
                                onChange={handleChangePrecision}
                            />
                        </Grid>
                        <Grid item container xs={12} justify="center">
                            <Button variant="contained" color="primary" onClick={handleClickFormatButton} disabled={formatDisabled}>Format</Button>
                        </Grid>
                        <Grid item container xs={12} justify="center">
                            <CopyToClipboardButton aria-label="copy current timestamp">
                                <Typography variant="h4" color="secondary">
                                    {unixTimestamp}
                                </Typography>
                            </CopyToClipboardButton>
                        </Grid>
                    </Grid>
                </form>
            </CardContent>
        </Card>
    );
}

export default DateTimeToUnixTimestamp;