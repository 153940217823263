import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from "@material-ui/core/Card";
import {CardContent} from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import CardHeader from "@material-ui/core/CardHeader";
import {AccessTime, Alarm, Code, Functions, Home, Http, Language, Timelapse, Timer, VpnKey} from "@material-ui/icons";
import {toHome, toPasswordGenerator, toUnixTimestampTools} from "../../services/routing";

function ToolList() {
    const useStyles = makeStyles(theme => ({
        card: {
            flexGrow: 1,
            background: "#eee",
            // height: "calc(100vh - 64px - 40px)" // (full-height) - (header) - (container-padding)
        }
    }));
    const classes = useStyles();

    return (
        <Card className={classes.card}>
            <CardHeader title="More tools" />
            <CardContent>
                <List component="nav" aria-label="more tools">
                    <ListItem button onClick={toHome}>
                        <ListItemIcon>
                            <Home />
                        </ListItemIcon>
                        <ListItemText primary="Home" />
                    </ListItem>
                    <Divider />
                    <ListItem aria-label="date and time tools">
                        <ListItemText primary="Date and time tools" />
                    </ListItem>
                    <Divider />
                    <ListItem button onClick={toUnixTimestampTools}>
                        <ListItemIcon>
                            <Timelapse />
                        </ListItemIcon>
                        <ListItemText primary="Unix timestamp tools" />
                    </ListItem>
                    <ListItem button onClick={toUnixTimestampTools}>
                        <ListItemIcon>
                            <AccessTime />
                        </ListItemIcon>
                        <ListItemText primary="Date time tools" />
                    </ListItem>
                    <ListItem button onClick={toUnixTimestampTools}>
                        <ListItemIcon>
                            <Language />
                        </ListItemIcon>
                        <ListItemText primary="Timezone tools" />
                    </ListItem>
                    <ListItem button onClick={toUnixTimestampTools}>
                        <ListItemIcon>
                            <Alarm />
                        </ListItemIcon>
                        <ListItemText primary="Alarm clock" />
                    </ListItem>
                    <ListItem button onClick={toUnixTimestampTools}>
                        <ListItemIcon>
                            <Timer />
                        </ListItemIcon>
                        <ListItemText primary="Timer" />
                    </ListItem>
                    <Divider />
                    <ListItem aria-label="security tools">
                        <ListItemText primary="Security tools" />
                    </ListItem>
                    <Divider />
                    <ListItem button onClick={toPasswordGenerator}>
                        <ListItemIcon>
                            <VpnKey />
                        </ListItemIcon>
                        <ListItemText primary="Password generator" />
                    </ListItem>
                    <ListItem button onClick={toUnixTimestampTools}>
                        <ListItemIcon>
                            <VpnKey />
                        </ListItemIcon>
                        <ListItemText primary="Passphrase generator" />
                    </ListItem>
                    <ListItem button onClick={toUnixTimestampTools}>
                        <ListItemIcon>
                            <VpnKey />
                        </ListItemIcon>
                        <ListItemText primary="Cypher key generator" />
                    </ListItem>
                    <Divider />
                    <ListItem aria-label="data transformation tools">
                        <ListItemText primary="Data transformation tools" />
                    </ListItem>
                    <Divider />
                    <ListItem button onClick={toUnixTimestampTools}>
                        <ListItemIcon>
                            <Code />
                        </ListItemIcon>
                        <ListItemText primary="JSON formatter" />
                    </ListItem>
                    <ListItem button onClick={toUnixTimestampTools}>
                        <ListItemIcon>
                            <Code />
                        </ListItemIcon>
                        <ListItemText primary="XML formatter" />
                    </ListItem>
                    <Divider />
                    <ListItem aria-label="api tools">
                        <ListItemText primary="API tools" />
                    </ListItem>
                    <Divider />
                    <ListItem button onClick={toUnixTimestampTools}>
                        <ListItemIcon>
                            <Http />
                        </ListItemIcon>
                        <ListItemText primary="API tester" />
                    </ListItem>
                    <Divider />
                    <ListItem aria-label="math tools">
                        <ListItemText primary="Math tools" />
                    </ListItem>
                    <Divider />
                    <ListItem button onClick={toUnixTimestampTools}>
                        <ListItemIcon>
                            <Functions />
                        </ListItemIcon>
                        <ListItemText primary="Percentage calculator" />
                    </ListItem>
                </List>
            </CardContent>
        </Card>
    );
}

export default ToolList;
