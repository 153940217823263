import {navigate} from "@reach/router";

function navigateTo(path) {
    navigate(path).then();
}

export function toHome() {
    navigateTo('/');
}

export function toUnixTimestampTools() {
    navigateTo('unixTimestampTools');
}

export function toPasswordGenerator() {
    navigateTo('passwordGenerator');
}

