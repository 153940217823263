import React, {useEffect, useState} from 'react';
import {KeyboardDateTimePicker,} from '@material-ui/pickers';

import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import DateFnsTzUtils from "./DateFnsTzUtils";
import MuiPickersTzUtilsProvider from "./MuiPickersTzUtilsProvider";

function DateTimeField(props) {
    const [dateTime] = useState(new Date());
    const [format, setFormat] = useState('yyyy-MM-dd HH:mm:ss');

    const FullWidthTextField = props => {
        return <TextField fullWidth {...props} />
    }

    useEffect(() => {
        setFormat(props.format.replace(/ZZZ/, 'xxx').replace(/Z+/, 'XX'));
    }, [props.format]);

    return (
    <MuiPickersTzUtilsProvider utils={DateFnsTzUtils} timeZone={props.timeZone}>
        <KeyboardDateTimePicker
            variant="inline"
            inputVariant="outlined"
            label="Date/Time"
            autoOk
            maxDate={new Date(2999, 12, 31, 23, 59, 59, 999)}
            format={format}
            value={dateTime}
            onChange={props.onChange}
            TextFieldComponent={FullWidthTextField}
        />
    </MuiPickersTzUtilsProvider>
    );
}

DateTimeField.propTypes = {
    value: PropTypes.instanceOf(Date).isRequired,
    onChange: PropTypes.func.isRequired,
    format: PropTypes.string.isRequired,
    timeZone: PropTypes.string.isRequired,
};

export default DateTimeField;