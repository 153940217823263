import React, {useState} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import {Pause, PlayArrow} from "@material-ui/icons";

function PausePlay(props) {
    const useStyles = makeStyles(theme => ({
        card: {
            // flexGrow: 1,
            background: "#eee",
            height: "calc(100vh - 64px - 40px)" // (full-height) - (header) - (container-padding)
        },
        pauseButton: {
            color: "red"
        },
        playButton: {
            color: "blue"
        }
    }));
    const classes = useStyles();

    const [paused, setPaused] = useState(props.paused);
    const [hoverPauseButton, setHoverPauseButton] = useState(false);

    function togglePause() {
        if(props.onPauseToggle) {
            props.onPauseToggle(!paused);
        }
        setPaused(!paused);
    }

    function onMouseEnter() {
        setHoverPauseButton(true)
    }

    function onMouseLeave() {
        setHoverPauseButton(false)
    }

    return (
        <IconButton onClick={togglePause} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            {!paused &&
                <Tooltip arrow title="Pause" aria-label="pause current timestamp">
                    <Pause/>
                </Tooltip>
            }
            {paused &&
                <Tooltip arrow title="Continue" aria-label="continue current timestamp">
                    {hoverPauseButton ?
                        <PlayArrow className={classes.playButton}/>
                        :
                        <Pause className={classes.pauseButton}/>
                    }
                </Tooltip>
            }
        </IconButton>
    );
}

export default PausePlay;