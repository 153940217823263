import React, {useState} from "react";
import Card from "@material-ui/core/Card";
import {CardContent} from "@material-ui/core";
import CopyToClipboardButton from "../utils/CopyToClipboardButton";
import CardHeader from "@material-ui/core/CardHeader";
import Typography from "@material-ui/core/Typography";
import {api} from "../../services/api";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import UnixTimestampPrecision from "./UnixTimestampPrecision";
import OptionHistoryService from "../../services/optionHistoryService";
import UnixTimestampField from "../utils/UnixTimestampField";
import {useMountEffect} from "../../services/hooks";
import TimeZoneField, {defaultTimeZone} from "../utils/TimeZoneField";
import DateTimeFormatField, {defaultFormatOptions} from "../utils/DateTimeFormatField";

function UnixTimestampToDateTime() {
    const formatHistoryService = new OptionHistoryService('unixTimestampToDateTime.recentlyUsedFormats', defaultFormatOptions());
    const timeZoneHistoryService = new OptionHistoryService('unixTimestampToDateTime.recentlyUsedTimesZone');

    const [unixTimestamp, setUnixTimestamp] = useState(new Date().getTime());
    const [precision, setPrecision] = useState('MILLI');
    const [precisionDisabled, setPrecisionDisabled] = useState({
        SEC: false, MILLI: false, MICRO: true, NANO: true
    });
    const [formatDisabled, setFormatDisabled] = useState(false);
    const [formatOptions, setFormatOptions] = useState(formatHistoryService.initializedOptionList);
    const [format, setFormat] = useState(formatHistoryService.initializedOption({ label: 'yyyy-MM-dd HH:mm:ss.SSS Z' }));
    const [timeZoneOptions, setTimeZoneOptions] = useState([]);
    const [timeZone, setTimeZone] = useState(timeZoneHistoryService.initializedOption(defaultTimeZone));
    const [dateTime, setDateTime] = useState(null);

    const convertToDate = function(unixTimestamp, format, timeZone, precision) {
        if(!timeZone) {
            setTimeZone(defaultTimeZone);
            timeZone = defaultTimeZone;
        }
        api.post('/unixTimestamp/toDate', {
            unixTimestamp: unixTimestamp,
            format: format.label,
            timeZone: timeZone.utcOffset,
            precision: precision
        })
        .then((response) => {
            setDateTime(response.data);
            formatHistoryService.addToRecentlyUsedOptions(format);
            const formats = formatHistoryService.getOptionList();
            setFormatOptions(formats);
            timeZoneHistoryService.addToRecentlyUsedOptions(timeZone);
            const timeZones = timeZoneHistoryService.getOptionList();
            setTimeZoneOptions(timeZones);
        });
    };

    useMountEffect(() => {
        convertToDate(unixTimestamp, format, timeZone, precision);
    }, [convertToDate, unixTimestamp, format, timeZone, precision]);

    function handleChangeUnixTimestamp(event) {
        const value = event.target.value;
        if(value && value.length >= 10) {
            if(value.length >= 19) {
                setPrecision('NANO');
                setPrecisionDisabled({ SEC: false, MILLI: false, MICRO: false, NANO: false });
                setFormatDisabled(false);
            } else if(value.length >= 16) {
                setPrecision('MICRO');
                setPrecisionDisabled({ SEC: false, MILLI: false, MICRO: false, NANO: true });
                setFormatDisabled(false);
            } else if(value.length >= 13) {
                setPrecision('MILLI');
                setPrecisionDisabled({ SEC: false, MILLI: false, MICRO: true, NANO: true });
                setFormatDisabled(false);
            } else if(value.length >= 10) {
                setPrecision('SEC');
                setPrecisionDisabled({ SEC: false, MILLI: true, MICRO: true, NANO: true });
                setFormatDisabled(false);
            }
        } else {
            setPrecision(null);
            setPrecisionDisabled({ SEC: true, MILLI: true, MICRO: true, NANO: true });
            setFormatDisabled(true);
        }
        setUnixTimestamp(value);
    }

    function handleChangePrecision(event, value) {
        setPrecision(value);
    }

    function handleChangeFormat(event, value) {
        setFormatDisabled(!value);
        if(!value) return;
        if(typeof value === 'string') {
            setFormat({ label: value, type: 'Recently used' });
        } else {
            setFormat(value);
        }
    }

    function handleChangeTimeZone(event, value) {
        setTimeZone(value);
    }

    function handleClickFormatButton() {
        convertToDate(unixTimestamp, format, timeZone, precision);
    }

    function handleSubmit(event) {
        if(event.key === 'Enter') {
            convertToDate(unixTimestamp, format, timeZone, precision);
        }
    }

    function handleTimeZonesFound(timeZones) {
        timeZones = timeZoneHistoryService.getOptionList(timeZones);
        setTimeZoneOptions(timeZones);
    }

    return (
        <Card>
            <CardHeader title="Unix timestamp to date/time" />
            <CardContent>
                <form noValidate autoComplete="off">
                    <Grid container direction="row" spacing={2} alignItems="stretch" justify="center">
                        <Grid item container xs={12} sm={6} lg={6}>
                            <UnixTimestampField
                                value={unixTimestamp}
                                onChange={handleChangeUnixTimestamp}
                                // onKeyDown={handleSubmit}
                            />
                        </Grid>
                        <Grid item container xs={12} sm={6} lg={6}>
                            <UnixTimestampPrecision
                                value={precision}
                                microPrecision={true}
                                nanoPrecision={true}
                                disabledOptions={precisionDisabled}
                                onChange={handleChangePrecision}
                            />
                        </Grid>
                        <Grid item container xs={12} sm={6} lg={6}>
                            <DateTimeFormatField
                                value={format}
                                options={formatOptions}
                                onChange={handleChangeFormat}
                                onKeyDown={handleSubmit}
                            />
                        </Grid>
                        <Grid item container xs={12} sm={6} lg={6}>
                            <TimeZoneField
                                value={timeZone}
                                options={timeZoneOptions}
                                onTimeZonesFound={handleTimeZonesFound}
                                onChange={handleChangeTimeZone}
                                onKeyDown={handleSubmit}
                            />
                        </Grid>
                        <Grid item container xs={12} justify="center">
                            <Button variant="contained" color="primary" onClick={handleClickFormatButton} disabled={formatDisabled}>Format</Button>
                        </Grid>
                        <Grid item container xs={12} justify="center">
                            <CopyToClipboardButton aria-label="copy current timestamp">
                                <Typography variant="h4" color="secondary">
                                    {dateTime}
                                </Typography>
                            </CopyToClipboardButton>
                        </Grid>
                    </Grid>
                </form>
            </CardContent>
        </Card>
    );
}

export default UnixTimestampToDateTime;