import React, {useEffect, useState} from "react";
import Card from "@material-ui/core/Card";
import {CardContent} from "@material-ui/core";
import CopyToClipboardButton from "../utils/CopyToClipboardButton";
import CardHeader from "@material-ui/core/CardHeader";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {api} from "../../services/api";
import TextField from "@material-ui/core/TextField";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Slider from "@material-ui/core/Slider";
import IconButton from "@material-ui/core/IconButton";
import {Replay, Restore, Save, SettingsBackupRestore} from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import CardActionArea from "@material-ui/core/CardActionArea";
import Button from "@material-ui/core/Button";
import CardActions from "@material-ui/core/CardActions";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Slide from "@material-ui/core/Slide";

function PasswordGenerator() {
    const optionsKey = 'passwordGenerator.options';

    const defaultOptions = {
        length: 16,
        numbers: '0123456789',
        lowercaseChars: 'abcdefghijklmnopqrstuvwxyz',
        uppercaseChars: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
        symbols: '!@#%^&*~<>?,;.:\\/|',
        includeNumbers: true,
        includeLowercaseChars: true,
        includeUppercaseChars: true,
        includeSymbols: true
    };

    const savedOptionsString = localStorage.getItem(optionsKey);
    const options = savedOptionsString ? JSON.parse(savedOptionsString) : defaultOptions;

    const [password, setPassword] = useState();
    const [passwordLength, setPasswordLength] = useState(() => options.length);
    const [numbers, setNumbers] = useState(() => options.numbers);
    const [lowercaseChars, setLowercaseChars] = useState(() => options.lowercaseChars);
    const [uppercaseChars, setUppercaseChars] = useState(() => options.uppercaseChars);
    const [symbols, setSymbols] = useState(() => options.symbols);
    const [includeNumbers, setIncludeNumbers] = useState(() => options.includeNumbers);
    const [includeLowercaseChars, setIncludeLowercaseChars] = useState(() => options.includeLowercaseChars);
    const [includeUppercaseChars, setIncludeUppercaseChars] = useState(() => options.includeUppercaseChars);
    const [includeSymbols, setIncludeSymbols] = useState(() => options.includeSymbols);
    const [initialized] = useState(() => true);

    useEffect(() => {
        if(initialized) {
            generateNewPassword();
        }
    }, [
        passwordLength,
        numbers,
        lowercaseChars,
        uppercaseChars,
        symbols,
        includeNumbers,
        includeLowercaseChars,
        includeUppercaseChars,
        includeSymbols
    ]);

    function assemblePasswordOptions() {
        const passwordOptions = { length: passwordLength };
        if(includeNumbers) {
            passwordOptions.numbers = numbers;
        }
        if(includeLowercaseChars) {
            passwordOptions.lowercaseChars = lowercaseChars;
        }
        if(includeUppercaseChars) {
            passwordOptions.uppercaseChars = uppercaseChars;
        }
        if(includeSymbols) {
            passwordOptions.symbols = symbols;
        }
        if(!passwordOptions.numbers && !passwordOptions.lowercaseChars && !passwordOptions.uppercaseChars && !passwordOptions.symbols) {
            setTransition(() => TransitionUp);
            setSnackOpen(true);
            throw new Error('At least one rule should be active')
        }
        return passwordOptions;
    }

    const generateNewPassword = function() {
        try {
            const passwordOptions = assemblePasswordOptions();
            console.log(passwordOptions);
            api.post('/passwordGenerator/generatePassword', passwordOptions)
            .then((response) => {
                 setPassword(response.data);
            });
        } catch (e) {
            console.error(e);
        }
    };

    const handleChangeNumbers = function(event) {
        const value = event.target.value;
        if(value && value !== numbers) {
            setNumbers(value);
        }
    };

    const handleChangeLowercaseChars = function(event) {
        const value = (event.target.value || '').toLowerCase();
        if(value && value !== lowercaseChars) {
            setLowercaseChars(value);
        }
    };

    const handleChangeUppercaseChars = function(event) {
        const value = (event.target.value || '').toUpperCase();
        if(value && value !== uppercaseChars) {
            setUppercaseChars(value);
        }
    };

    const handleChangeSymbols = function(event) {
        const value = event.target.value;
        if(value && value !== symbols) {
            setSymbols(value);
        }
    };

    const handleChangePasswordLengthSlider = function(event, value) {
        if(value !== passwordLength) {
            setPasswordLength(value);
        }
    };

    const handleChangePasswordLength = function(event) {
        const value = event.target.value;
        if(value && value !== passwordLength) {
            setPasswordLength(value);
        }
    };

    const handleChangeIncludeNumbers = function(event) {
        const value = event.target.checked;
        setIncludeNumbers(value);
    };

    const handleChangeIncludeLowercaseChars = function(event) {
        const value = event.target.checked;
        setIncludeLowercaseChars(value);
    };

    const handleChangeIncludeUppercaseChars = function(event) {
        const value = event.target.checked;
        setIncludeUppercaseChars(value);
    };

    const handleChangeIncludeSymbols = function(event) {
        const value = event.target.checked;
        setIncludeSymbols(value);
    };

    function saveOptions() {
        const options = {
            length: passwordLength,
            numbers: numbers,
            lowercaseChars: lowercaseChars,
            uppercaseChars: uppercaseChars,
            symbols: symbols,
            includeNumbers: includeNumbers,
            includeLowercaseChars: includeLowercaseChars,
            includeUppercaseChars: includeUppercaseChars,
            includeSymbols: includeSymbols
        };
        localStorage.setItem(optionsKey, JSON.stringify(options));
    }

    function restoreDefault() {
        setPasswordLength(defaultOptions.length);
        setNumbers(defaultOptions.numbers);
        setLowercaseChars(defaultOptions.lowercaseChars);
        setUppercaseChars(defaultOptions.uppercaseChars);
        setSymbols(defaultOptions.symbols);
        setIncludeNumbers(defaultOptions.includeNumbers);
        setIncludeLowercaseChars(defaultOptions.includeLowercaseChars);
        setIncludeUppercaseChars(defaultOptions.includeUppercaseChars);
        setIncludeSymbols(defaultOptions.includeSymbols);
    }

    const [snackOpen, setSnackOpen] = React.useState(false);
    const [transition, setTransition] = React.useState(undefined);

    const handleClose = () => {
        setSnackOpen(false);
    };

    function TransitionUp(props) {
        return <Slide {...props} direction="up" />;
    }

    return (
        <Card>
            <CardHeader title="Generate a password based on configurable rules" />
            <CardContent>
                <Grid container direction="row" justify="center" spacing={2}>
                    <Grid item container justify="center" xs={12}>
                        <div style={{width: '36px'}} />
                        <CopyToClipboardButton aria-label="copy generated password">
                            <Typography variant="h4" color="secondary" style={{textTransform: 'none'}}>
                                {password}
                            </Typography>
                        </CopyToClipboardButton>
                        <div style={{padding: '10px 0'}}>
                            <IconButton onClick={generateNewPassword}>
                                <Tooltip arrow title="Generate new password" aria-label="generate new password">
                                    <Replay />
                                </Tooltip>
                            </IconButton>
                        </div>
                    </Grid>
                    <Grid item container alignItems="center" spacing={2}>
                        <Grid item xs={4} md>
                            <TextField label="Length" variant="outlined"
                                       value={passwordLength}
                                       onChange={handleChangePasswordLength}
                            />
                        </Grid>
                        <Grid item xs={8} md={10}>
                            <Slider
                                value={typeof passwordLength === 'number' ? passwordLength : 0}
                                onChange={handleChangePasswordLengthSlider}
                                aria-labelledby="password-length"
                                step={1} min={8} max={32}
                                valueLabelDisplay="on"
                                style={{marginTop: '30px'}}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormGroup row>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={includeNumbers}
                                        onChange={handleChangeIncludeNumbers}
                                        color="primary"
                                    />
                                }
                                label="Include numbers:"
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <TextField variant="outlined" size="small"
                                   fullWidth
                                   disabled={!includeNumbers}
                                   value={numbers}
                                   onChange={handleChangeNumbers}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormGroup row>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={includeLowercaseChars}
                                        onChange={handleChangeIncludeLowercaseChars}
                                        color="primary"
                                    />
                                }
                                label="Include lowercase characters:"
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <TextField variant="outlined" size="small"
                                   fullWidth
                                   disabled={!includeLowercaseChars}
                                   value={lowercaseChars}
                                   onChange={handleChangeLowercaseChars}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormGroup row>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={includeUppercaseChars}
                                        onChange={handleChangeIncludeUppercaseChars}
                                        color="primary"
                                    />
                                }
                                label="Include uppercase characters"
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <TextField variant="outlined" size="small"
                                   fullWidth
                                   disabled={!includeUppercaseChars}
                                   value={uppercaseChars}
                                   onChange={handleChangeUppercaseChars}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormGroup row>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={includeSymbols}
                                        onChange={handleChangeIncludeSymbols}
                                        color="primary"
                                    />
                                }
                                label="Include symbols"
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <TextField variant="outlined" size="small"
                                   fullWidth
                                   disabled={!includeSymbols}
                                   value={symbols}
                                   onChange={handleChangeSymbols}
                        />
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions>
                <Grid item container justify="flex-end" spacing={2}>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="default"
                            startIcon={<SettingsBackupRestore/>}
                            onClick={restoreDefault}
                        >
                            Restore default
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<Save/>}
                            onClick={saveOptions}
                        >
                            Save options
                        </Button>
                    </Grid>
                </Grid>
                <Snackbar
                    open={snackOpen}
                    onClose={handleClose}
                    TransitionComponent={transition}
                    key="no-rule-active"
                >
                    <Alert elevation={6} variant="filled" onClose={handleClose} severity="error">
                        At least 1 rule should be active
                    </Alert>
                </Snackbar>
            </CardActions>
        </Card>
    );
}

export default PasswordGenerator;